<div class="top-menu-wrapper lato">
    <div *ngIf="!isLogin else logged" class="desktop-top-menu" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-left: 20px;">
            <ng-container *ngIf="isBannerExist">
                <button  appToggBtn (click)="toggleBanner()"></button>
                <p i18n style="padding-left: 8px;">Hide Promotions</p>
            </ng-container>
            <ng-container *ngIf="!isBannerExist">
                <span style="width: 132px;"></span>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center"  fxLayoutGap="24px" class="top-menu-wrapper_links">
            <a [routerLink]="slots" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><img src="assets/icons/icon-menu-slot.svg" alt="inline-logo" /><span i18n>Slots</span></a>
            <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
            <a [routerLink]="highFlyerUrl" routerLinkActive="active" ><img src="assets/icons/icon-menu-high-flyer.svg" alt="inline-logo" /><span i18n>High Flyer</span></a>
            <a [routerLink]="liveCasinoUrl" routerLinkActive="active" ><img src="assets/icons/icon-menu-live-casino.svg" alt="inline-logo" /><span i18n>Live Casino</span></a>
            <!-- <a [routerLink]="promotionsTrx" routerLinkActive="active" i18n>Promotions</a> -->
            <!-- <a [topDrawerRouterLink]="[lostPasswordTrx]" i18n>lost password</a> -->
        </div>
        <span style="width: 152px;"></span>
    </div>
</div>
<ng-template #logged>
    <div class="desktop-top-menu lato" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-left: 20px;">
            <ng-container *ngIf="isBannerExist">
                <button  appToggBtn (click)="toggleBanner()"></button>
                <p i18n style="padding-left: 8px;">Hide Promotions</p>
            </ng-container>
            <ng-container *ngIf="!isBannerExist">
                <span style="width: 132px;"></span>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center"  fxLayoutGap="24px" class="top-menu-wrapper_links">
            <a [routerLink]="['',slots]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" ><img src="assets/icons/icon-menu-slot.svg" alt="inline-logo" /><span i18n>Slots</span></a>
            <!-- <a *ngIf="!isInGame" [routerLink]="['']"><img class="inline-logo" alt="inline-logo" src="assets/pages/promobb/logo-casino-white.svg" /></a> -->
            <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
            <!-- <a [routerLink]="aviatorUrl" routerLinkActive="active" i18n>Aviator</a> -->
            <a [routerLink]="highFlyerUrl" routerLinkActive="active" ><img src="assets/icons/icon-menu-high-flyer.svg" alt="inline-logo" /><span i18n>High Flyer</span></a>
            <a [routerLink]="liveCasinoUrl" routerLinkActive="active" ><img src="assets/icons/icon-menu-live-casino.svg" alt="inline-logo" /><span i18n>Live Casino</span></a>
            <!-- <a [routerLink]="prifileTrx" i18n>profile</a> -->
        </div>
        <span style="width: 152px;"></span>
    </div>
</ng-template>